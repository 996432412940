import React, { FC } from 'react';
import logo from './logo.svg';
import '../App.css';
import { Box, Button, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import RegisterEmailDialog from "../registerForUpdates/RegisterEmailDialog";

interface ComingSoonProps {
    
}

const ComingSoonApp: FC<ComingSoonProps> = (props) => {
    const theme = useTheme()
    const cardBackgroundColor = theme.palette.primary.main;

    const [showRegisterDialog, setShowRegisterDialog] = React.useState(false);

    return (
        <div className="App">
            <Box sx={{ bgcolor: "background.paper", width: '100vw', height: '100vh', }} display="flex">
                <Box justifyContent="center" alignItems="center" m="auto">
                    <Card variant="outlined" elevation={12} >
                        <CardContent sx={{ minWidth: 200, minHeight: 200, maxWidth: 400, maxHeight: 400 }}>
                            <Typography variant="h5" component="div">
                                Coming Soon
                            </Typography>
                            <Typography variant="body1">
                                NeverStopLearning will host courses and one-off videos centered around software development.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="large" onClick={() => setShowRegisterDialog(true)}>Notify Me</Button>
                        </CardActions>
                    </Card>
                </Box>
            </Box>

            <RegisterEmailDialog open={showRegisterDialog} onClose={() => setShowRegisterDialog(false)} />
        </div>
    );
}

export default ComingSoonApp;
