import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { setegid } from 'process';

interface RegisterEmailDialogProps {
    context?: string,
    open: boolean,
    onClose: () => void,
}

function registerEmail(email: string) {
    fetch(`https://us-central1-neverstoplearning-775da.cloudfunctions.net/registerEmailForLaunchNotification?email=${email}`)
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
}

function validateEmail(email: string): boolean {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
    }
    return false
}

const RegisterEmailDialog: React.FC<RegisterEmailDialogProps> = (props) => {
    const [email, setEmail] = React.useState("");
    const [emailValid, setEmailValid] = React.useState(true);

    const handleSubscribe = () => {
        let currentEmail = email
        if (validateEmail(currentEmail)) {
            registerEmail(currentEmail);
            handleClose();
        } else {
            setEmailValid(false)
        }
    };
    const handleClose = () => {
        setEmail("");
        setEmailValid(true);
        props.onClose();
    };

    return <Dialog open={props.open}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Register to be notified when NeverStopLearning officially launches.
            </DialogContentText>
            <TextField
                autoFocus
                value={email}
                onChange={(e) => {
                    let curr = e.target.value;
                    setEmail(curr);
                    if (!emailValid) {
                        if (validateEmail(curr)) {
                            setEmailValid(true)
                        }
                    }
                }}
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                error={!emailValid}
            />
        </DialogContent>
        <DialogActions>
            <Button color="error" onClick={handleClose}>Cancel</Button>
            <Button color="success" onClick={handleSubscribe}>Subscribe</Button>
        </DialogActions>
    </Dialog>
}

export default RegisterEmailDialog;