import React from 'react';
import logo from './logo.svg';
import './App.css';
import NSLAppBar from './appbar/NSLAppBar';
import { Box, IconButton, PaletteMode, ThemeProvider } from '@mui/material';
import { createNewTheme } from './theme/NSLTheme';
import ComingSoonApp from './comingsoon/ComingSoonApp';

function App() {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  const theme = React.useMemo(() => createNewTheme(mode), [mode])
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <NSLAppBar onThemeToggled={colorMode.toggleColorMode} />
        <Box>
          <ComingSoonApp />
        </Box>
      </div>
    </ThemeProvider>

  );
}

export default App;
