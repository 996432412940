import { createTheme, PaletteMode } from "@mui/material";
import { blue, purple, indigo, blueGrey, grey } from "@mui/material/colors";

export function createNewTheme(mode: PaletteMode) {
    return createTheme({
        palette: {
            mode: mode,
            primary: {
                main: mode == "dark" ? indigo[500] : blue[700],
                dark: mode == "dark" ? indigo[900] : blue[900],
            },
            secondary: {
                main: mode == "dark" ? grey[800] : grey[100],
            },
            background: {
                paper: mode == "dark" ? grey[900] : grey[100],
            }
        },
    });
}